import React from "react"
import { Container, Row, Col, Badge, Button } from "reactstrap"
import { Link } from "gatsby"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import ViewMoreFeatures from "src/components/features/ViewMoreFeatures"

import Heading from "src/components/index/Heading"

import healthcodeLogo from "src/assets/img/healthcode-logo.jpg"

const FeaturePracticeManagement = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Features | Billing"
        pathname="/features/billing"
        description="Carebit features extensive billing features, allowing you to easily invoice electronically with Healthcode and swiftly issue excess invoices to patients."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Features
              </Badge>
              <h1>Billing</h1>
              <p className="u-whiteTranslucent">
                Processing millions of pounds worth of invoices every year for
                private practices and billing companies alike, Carebit makes
                billing insurance companies, patients and third parties easy.
                It’s fully integrated with the Healthcode system, so you can
                easily send invoices swiftly to insurance companies for
                settlement.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <section>
            <Row className="u-centerFlex">
              <Col lg={5} md={12} className="SplitContentRow-graphic">
                <img
                  alt="Healthcode insurance billing"
                  src={healthcodeLogo}
                  className="u-outline"
                  style={{ width: "100%", padding: "60px" }}
                />
              </Col>
              <Col
                lg={{ size: 6, offset: 1 }}
                md={{ size: 12 }}
                className="SplitContentRow-text"
              >
                <Row>
                  <Heading title="Fully integrated with Healthcode">
                    Swift, easy electronic billing to insurers, patients and
                    third parties
                  </Heading>
                </Row>
                <p className="u-midGrey">
                  A large part of your clinic’s revenue will come from insurance
                  companies, and Carebit makes it easy to keep on top of this.
                  You can invoice directly from your clinic list, and Carebit
                  even remembers your billing codes and prices - making it
                  really quick and easy to create invoices.
                </p>

                <p className="u-midGrey">
                  Invoices (including excess invoices) can be issued to patients
                  to pay online, and you can also easily bill to third parties
                  such as medico-legal firms and hospitals. When payment is
                  received from an insurer, advanced features allow you to
                  quickly record payments made in bulk and issue excess invoices
                  for your patients to{" "}
                  <Link to="/features/online-payment" className="u-fontBold">
                    pay online
                  </Link>{" "}
                  with just one click.
                </p>

                <div style={{ marginTop: "40px" }}>
                  <Link to="/features/online-payment">
                    <Button color="secondary" size="md">
                      Read about online payment &rarr;
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </section>
        </Container>

        <ViewMoreFeatures />
      </div>
    </Layout>
  )
}

export default FeaturePracticeManagement
